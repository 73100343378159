<div class="container app-container">
  <h1 class="app-heading">
    <ng-container i18n>Available Omniopti products</ng-container>
  </h1>

  <ng-container *ngIf="products.length > 0">
    <div class="d-flex flex-wrap mt-2">
      <app-dashboard-card [product]="product" *ngFor="let product of products"></app-dashboard-card>
    </div>
    <span i18n>Contact <a href="mailto:info@omniopti.eu">info&#64;omniopti.eu</a> for more information.</span>
  </ng-container>

  <ng-container *ngIf="products.length === 0">
    <span i18n>No available products. Please contact <a href="mailto:info@omniopti.eu">info&#64;omniopti.eu</a> for more information.</span>
  </ng-container>
</div>
