import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { DashboardPageComponent } from "./dashboard/dashboard-page/dashboard-page.component";
import { DashboardCardComponent } from "./dashboard/dashboard-card/dashboard-card.component";
import { NavbarComponent } from "./layout/navbar/navbar.component";
import { CompanyLoginComponent } from "./company-login/company-login.component";
import { NgxPermissionsModule } from "ngx-permissions";
import { CoreModule } from "./core/core.module";
import { AuthGuard } from "./core/auth-guard.service";
import { NoAuthGuard } from "./core/no-auth-guard.service";
import { LoginErrorComponent } from "./login-error/login-error.component";
import { SuperAdminGuard } from "./core/super-admin-guard.service";
import { CompanyManagementPageComponent } from "./company-management/company-management-page/company-management-page.component";
import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { CompanyViewComponent } from "./company-management/company-view/company-view.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CheckboxComponent } from "./shared/checkbox/checkbox.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CompanyUsersComponent } from "./company-management/company-users/company-users.component";
import { ConfirmationModalComponent } from "./shared/confirmation-modal/confirmation-modal.component";
import { MultiCheckboxInputComponent } from "./shared/multi-checkbox-input/multi-checkbox-input.component";
import { ManageMyCompanyPageComponent } from "./company-management/manage-my-company-page/manage-my-company-page.component";
import { UserProfileComponent } from "./user/user-profile/user-profile.component";
import { CompanyAdminGuard } from "./core/company-admin-guard.service";
import { AccordionWithContentComponent } from "./shared/accordion-with-content/accordion-with-content.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LanguagePickerComponent } from "./shared/language-picker/language-picker.component";

@NgModule({
  declarations: [
    AppComponent,
    DashboardPageComponent,
    DashboardCardComponent,
    NavbarComponent,
    CompanyLoginComponent,
    LoginErrorComponent,
    CompanyManagementPageComponent,
    SpinnerComponent,
    CompanyViewComponent,
    CheckboxComponent,
    CompanyUsersComponent,
    ConfirmationModalComponent,
    MultiCheckboxInputComponent,
    ManageMyCompanyPageComponent,
    UserProfileComponent,
    AccordionWithContentComponent,
    LanguagePickerComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    CoreModule.forRoot(),
    FontAwesomeModule,
    RouterModule.forRoot(
      [
        { path: "", component: DashboardPageComponent, pathMatch: "full", canActivate: [AuthGuard] },
        {
          path: "login",
          component: CompanyLoginComponent,
          pathMatch: "full",
          canActivate: [NoAuthGuard],
        },
        {
          path: "login-error",
          component: LoginErrorComponent,
          pathMatch: "full",
          canActivate: [NoAuthGuard],
        },
        {
          path: "company-management",
          component: CompanyManagementPageComponent,
          pathMatch: "full",
          canActivate: [AuthGuard, SuperAdminGuard],
        },
        {
          path: "manage-my-company",
          component: ManageMyCompanyPageComponent,
          pathMatch: "full",
          canActivate: [AuthGuard, CompanyAdminGuard],
        },
        {
          path: "company/:id",
          component: CompanyViewComponent,
          pathMatch: "full",
          canActivate: [AuthGuard, SuperAdminGuard],
        },
        {
          path: "profile",
          component: UserProfileComponent,
          pathMatch: "full",
          canActivate: [AuthGuard],
        },
      ]
    ),
    NgxPermissionsModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
