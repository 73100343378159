import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/auth.service";
import { Product } from "src/app/models/Product";
import { Role } from "src/app/models/Role";

@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.css"],
})
export class DashboardPageComponent implements OnInit {
  isLoading = true;

  products: Product[] = [];

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.authService.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (!isDone) {
        return;
      }

      if (this.authService.loggedInUser.roles.includes(Role.SUPER_ADMIN)) {
        this.router.navigate(["/company-management"]);
        return;
      }

      this.products = this.authService.loggedInUser.allowedProducts.map((product) => ({
        ...product,
        logoUrl: "assets/logo.png",
      }));
      this.isLoading = false;
    });
  }
}
