export const environment = {
  production: true,
  authServerUrl: "https://omniapp-test.omniopti.si",
  languages: [
    {
      name: "Slovenian",
      link: "https://sl.dashboard.warehousing.omniopti.eu",
    },
    {
      name: "English",
      link: "https://dashboard.warehousing.omniopti.eu",
    },
  ],
  apiUrl: "https://omniapp-test.omniopti.si/dashboard",
};
