<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="authService.loggedInUser">
  <a class="navbar-brand" [routerLink]="['/']">
    <img src="assets/logo.png" alt="Home page" width="30" height="30" class="d-inline-block align-top" />
    Omniopti dashboard
  </a>

  <button class="navbar-toggler" type="button" (click)="toggleNavbar = !toggleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse">

    <ul class="navbar-nav mr-auto">
      <li class="nav-item" *ngxPermissionsExcept="Role.SUPER_ADMIN">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/']">
          <ng-container i18n=>Home</ng-container>
        </a> 
      </li>
      <li class="nav-item" *ngxPermissionsOnly="Role.SUPER_ADMIN">
        <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/company-management']">
          <ng-container i18n=>Company management</ng-container>
        </a> 
      </li>
        <li class="nav-item" *ngxPermissionsOnly="Role.COMPANY_ADMIN"> 
          <a class="nav-link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/manage-my-company']">
            <ng-container i18n=>My company management</ng-container>
          </a>
        </li>
     </ul>
 
    <ul class="form-inline my-2 my-lg-0 navbar-nav">
      <li class="nav-item"  *ngxPermissionsExcept="Role.SUPER_ADMIN">
        <a class="nav-link"  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/profile']">
          <ng-container>{{ authService.loggedInUser.firstName }} {{ authService.loggedInUser.lastName }} </ng-container>
          <ng-container *ngIf="authService.loggedInUser.company">({{ authService.loggedInUser.company.name }})</ng-container>
        </a>
      </li>
      <div class="col-auto nav-item">
        <button class="btn btn-secondary" (click)="authService.logout()">Log out</button>
      </div>
      <app-language-picker></app-language-picker>
    </ul>
  </div>
</nav>
